export class News {
  id: string;
  archivo: string;
  fecha: string;
  tema_id: string;
  tema_name: string;
  audiencia: string;
  autor: string;
  descriptores: string;
  medio_id: string;
  medio_name: string;
  numero_paginas: number;
  ocupacion: string;
  pagina: string;
  procedencia: string;
  seccion: string;
  superficie: string;
  texto_libre: string;
  tipo_medio: string;
  titular: string;
  valor_economico: string;
  cadena_id: string;
  cadena_name: string;
  duracion: string;
  hora_inicio: string;
  programa: string;
  resumen: string;
  ranking: string;
  tipologia: string;
  url: string;
  url_noticia: string;
  tipo_noticia_id: number;
  imagen: string;
}
