import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-page-master',
  templateUrl: './page-master.component.html',
  styleUrls: ['./page-master.component.scss']
})
export class PageMasterComponent implements OnInit {

  constructor(
    
    private router: Router,
    
  ) { }

  ngOnInit() {
  //  alert(this.router.url);
    document.body.style.overflowY = "hidden";
    setTimeout(() => {
      $('html,body').scrollTop(0);
    }, 500);
    
   }




}
