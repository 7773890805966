import { Component, OnInit, ViewChild } from '@angular/core';
import { NewsService } from '../_services/news.service';
import { News } from '../_models/news';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { Subscription } from 'rxjs';
import { SearchFilter } from '../_models/search_filter';
import { saveAs } from 'file-saver';


/**
 * Nav sidebar materialize functions
 */
/* declare function isSidebarInitialized(): boolean;
declare function isSidebarOpen(): boolean;
declare function closeSidebar(): void; */


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: [
    './news.component.scss'
  ]
})
export class NewsComponent implements OnInit {


  removeDuplicateWords = s => s.replace(/(\b\S.+\b)(?=.*\1)/g, "").replace(/^,/, '');

  newsCollection: News[] = [];
  baseUrl: string;
  prensaMediaUrl: string;userKeywords
  onlineMediaUrl: string;
  tvMediaUrl: string;
  radioMediaUrl: string;


  NOTICIA_PRENSA: number = 1;
  NOTICIA_TELEVISION: number = 2;
  NOTICIA_RADIO: number = 3;
  NOTICIA_ONLINE: number = 4;

  type: number = 0;
  search: string = '';
  keywords: string;
   newkw = [];


  selectedSearchFilter: SearchFilter;

  /** Subscribers */
  private newsSearchSubscription: Subscription;
  private selectedSearchFilterSubscription: Subscription;


  constructor(
    private userService: UserService,
    private newsService: NewsService,
    private router: Router
  ) {
    this.loadUrls();

    this.subscribe();
  }

  regionES='';
  regionAR='';
  regionCL='';
  regionCO='';
  regionUY='';
  regionPE='';
  regionMX='';
  regexp = /[|]{2}/g;
  /** COMPONENT FUNCTIONS */
  ngOnInit() {
    if (typeof this.userService.keywords !== 'undefined') {
      console.log("entra a news component:"+this.userService.keywords);
    } else {
      console.log("entra a news component");
    }
    let urlName = this.router.url.replace('/', '');
    switch (urlName) {
      case 'news/prensa':
        this.type = 1;
        if(this.userService.detail==true){
          this.userService.detail = false;

        }else{
         // this.userService.search = '';
        }

        break;
      case 'news/tv':
        this.type = 2;
        if(this.userService.detail==true){
          this.userService.detail = false;
        }else{
        //  this.userService.search = '';
        }
        break;
      case 'news/radio':
        this.type = 3;
        if(this.userService.detail==true){
          this.userService.detail = false;
        }else{
        //  this.userService.search = '';
        }
        break;
      case 'news/online':
        this.type = 4;
        if(this.userService.detail==true){
          this.userService.detail = false;
        }else{
      //    this.userService.search = '';
        }
        break;
    }
 //   console.log(this.newsService.applyFilter);
    if(urlName=="news/all" ){
      //console.log(this.newsService.applyFilter);
      this.newsService.searchIsEmpty();
      let userKeywords=this.newsService.keywords_temp.toString();


      this.search = this.userService.search;
      this.userService.tipoNoticia = this.type;
      //console.log('this.userService.tipoNoticia: ', this.userService.tipoNoticia);

      if (this.userService.selectedSearchFilter !== undefined) {
        this.selectedSearchFilter = this.userService.selectedSearchFilter;
      }

      if (!(this.newsCollection.length > 0)&& this.newsService.applyFilter ==1) {
        if (this.userService.selectedSearchFilter){
          this.newsService.searchByFilter(this.userService.tipoNoticia, this.userService.selectedSearchFilter);
          if(userKeywords.length>0){

            let keywords=userKeywords.split(',');
            keywords.forEach(el => {
              if (!(el in this.newkw)) {
                this.newkw[el] = true;
                this.newkw.push(el);
              }
            });

            this.newkw.forEach(element => {
              if(element!=''){
                this.selectedSearchFilter.keywords.push(element.trim());
              }
            });
          }



          this.selectedSearchFilter=this.userService.selectedSearchFilter;
        }
        else{
        //  alert('entra 3');
          this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);}
      }
      if (this.selectedSearchFilter && this.selectedSearchFilter.keywords) {
      this.selectedSearchFilter.keywords=this.newkw;
      }
    }
    if(urlName!="news/all"){
      //console.log(this.newsService.applyFilter);
      this.newsService.searchIsEmpty();
      let userKeywords=this.newsService.keywords_temp.toString();



      this.newsService.applyFilter=1;

      this.search = this.userService.search;
      this.userService.tipoNoticia = this.type;
      //console.log('this.userService.tipoNoticia: ', this.userService.tipoNoticia);
  console.log(this.userService.selectedSearchFilter);
      if (this.userService.selectedSearchFilter !== undefined) {
        this.selectedSearchFilter = this.userService.selectedSearchFilter;
      }

      if (!(this.newsCollection.length > 0)) {
        if (this.userService.selectedSearchFilter){
          this.newsService.searchByFilter(this.userService.tipoNoticia, this.userService.selectedSearchFilter);
          if(userKeywords.length>0){

            let keywords=userKeywords.split(',');
            keywords.forEach(el => {
              if (!(el in this.newkw)) {
                this.newkw[el] = true;
                this.newkw.push(el);
              }
            });
            console.log("=====>"+this.userService.keywords);
            this.newkw.forEach(element => {
              if(element!=''){
                this.selectedSearchFilter.keywords.push(element.trim());
              }

            });
          }

          this.selectedSearchFilter.keywords=this.newkw;
        }
        else{
          this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
        }

      }
    }
  }

  ngAfterViewInit() {


    //Handles the popup sidebar
    /*     if (isSidebarInitialized() && isSidebarOpen())
          closeSidebar(); */
  }

  ngAfterViewChecked() {

    //Handles the popup sidebar
    /*     if (isSidebarInitialized() && isSidebarOpen())
          closeSidebar(); */

  $('audio').not( "[name='subs']" ).on('play', function () {
  console.log('play'); //cuando un audio empieza a reproducirse;
  var currentaudio=this;
// $(this).attr("name","subs");
  $('audio').each(function() {
    let audioPlayer: HTMLMediaElement =<HTMLMediaElement> this ;
    if (audioPlayer!== currentaudio) { //todos los demás
      audioPlayer.pause();  //los pausamos
    }
   });
});
  $('audio').attr("name","subs");


   $('video').not( "[name='subs']" ).on('play', function () {
  console.log('play'); //cuando un audio empieza a reproducirse;
  var currentvideo=this;
// $(this).attr("name","subs");
  $('video').each(function() {
    let videoPlayer: HTMLMediaElement =<HTMLMediaElement> this ;
    if (videoPlayer!== currentvideo) { //todos los demás
      videoPlayer.pause();  //los pausamos
    }
   });
});
  $('video').attr("name","subs");


  }

  ngOnDestroy() {
    this.newsSearchSubscription.unsubscribe();
    this.selectedSearchFilterSubscription.unsubscribe();

  }


  /** ACTIONS */
  loadUrls(): void {
    this.baseUrl = this.newsService.baseUrl;

    this.prensaMediaUrl = this.baseUrl + 'storage/files/news/prensa/';
    this.onlineMediaUrl = this.baseUrl + 'storage/files/news/online/';
    this.tvMediaUrl = this.baseUrl + 'storage/files/news/tv/';
    this.radioMediaUrl = this.baseUrl + 'storage/files/news/radio/';
  }

  checkNewsType(type, typeToCheck): boolean {
    return (parseInt(type) === typeToCheck);
  }

  removeKeywordPreference(index: number): void {
    this.newsService.search_temp.replace(this.selectedSearchFilter.keywords[index],'');
    this.selectedSearchFilter.keywords.splice(index, 1);
    this.newsService.keywords_temp.splice(index, 1);

    if(this.selectedSearchFilter.keywords.length==0){
      this.selectedSearchFilter.start_date=null;
      this.selectedSearchFilter.end_date=null;
      this.newsService.search_temp='';
      this.newsService.keywords_temp=[];

    }
    this.userService.selectSearchFilter(this.selectedSearchFilter);
    this.newsService.searchByFilter(this.userService.tipoNoticia, this.selectedSearchFilter);

    if(this.selectedSearchFilter.keywords.length==0){
      this.selectedSearchFilter=null;
      this.newsService.search_temp='';
      this.newsService.keywords_temp=[];
    }
  }

  viewMoreNews() {
    this.newsService.page ++;

    if (this.userService.selectedSearchFilter && this.userService.search=="")
      this.newsService.searchByFilter(this.userService.tipoNoticia, this.userService.selectedSearchFilter, this.newsService.page);
    else
      this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search, this.newsService.page);
  }

  onScrollDown(){
    //console.log('onScrollDown');
    this.viewMoreNews();
  }

  newSelected(news){
   // console.log(news);
    this.userService.setSelectedNews(news.id,news);

   // console.log(this.userService.selectedNewsObjectPrensa)
  }
  sendSelectedNews(){
    return this.userService.getSelectedNews().length;
  }


  private subscribe(): void {
    /**
     * Suscrito a las búsquedas de noticias
     */
    this.newsSearchSubscription = this.newsService.newsSearchGetMessage()
      .subscribe((news) => {
        this.regionES=localStorage.getItem('regionES');
        this.regionAR=localStorage.getItem('regionAR');
        this.regionCL=localStorage.getItem('regionCL');
        this.regionCO=localStorage.getItem('regionCO');
        this.regionUY=localStorage.getItem('regionUY');
        this.regionMX=localStorage.getItem('regionMX');
        this.regionPE=localStorage.getItem('regionPE');

        if (this.newsService.page > 1){
          this.newsCollection = this.newsCollection.concat(news);
          this.newsService.setNewsCollection(this.newsCollection);//Seteando nuevamente la colección de noticias, usada en detail.component
          //console.log('page > 1', this.newsCollection);
          return;
        }
        this.newsCollection = news.slice(0);
        $('#loading-container2').hide();
      });

    /**
     * Suscrito a la selección de filtros guardados y
     * quitar/eliminar keywords del filtro seleccionado en NewsComponent
     */
    this.selectedSearchFilterSubscription = this.userService.searchFilterSelectedGetMessage()
      .subscribe((searchFilter) => {
        this.selectedSearchFilter = searchFilter;
      });
  }
  save(url,name){
    //this.userService.logActionDownload(name);
   // var FileSaver = require('file-saver');
    saveAs.saveAs(url, name);
  }

}
