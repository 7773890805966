export class SearchFilter {
  

  constructor(
    public id: number,
    public user_id: string,
    public name: string,
    public description: string,
    public keywords: string[],
    public start_date: string,
    public end_date: string,
    public notification:number,
    public notification_type:string,
    public notification_day:string,
    public region:string,
    public medios:string,
    public restrictiva:boolean
  ){
    
  }

}