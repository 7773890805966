import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  //apiUrl: string = 'https://clipping.prhge.com/api';
  //apiUrl: string = 'http://clipping-laravel.local/api';
  baseUrl: string = environment.clippingBaseUrl;
  private authenticated: boolean = false;

  user: User;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    this.checkAuth();
    
  }

  
  /**
   * Verifica si está logueado
   */
  checkAuth(): void {
    if (localStorage.getItem('user')) {
      this.authenticated = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.checkUserLogged(this.user).subscribe((response)=> {
    });
    }

  }


  checkUserLogged(user: User): Observable<any> {
    let url = this.baseUrl + 'api/user/checkAuth';

    let headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    
    headers.append('Access-Control-Allow-Origin', "*");

    let postData = { userid: this.user.id };


    return this.http.post<any>(url, postData)
    .pipe(map((resp) => {

    }));
  }


  authenticate(username: any, password: any): Observable<boolean> {
    let url="";
    if(environment.authentication=='ldap'){
      url = this.baseUrl + 'api/user/login'; // use ldap to login
    }else{
      url =this.baseUrl + 'api/rest/user/login'; // use api to login
    }
   
    let headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    
    let postData = { username: username, password: password };
  //  let headers = new Headers({ "content-type": "application/json", });
    headers.append('Access-Control-Allow-Origin', "*");
    

    return this.http.post<any>(url, postData)
      .pipe(map((resp) => {
   //     console.log(resp);
        if (resp.response.status === 1) {
          let user = resp.response.data.user;
          let sociedad = user.sociedad;
          switch(sociedad) { 
            case 'ES': { 
             /* localStorage.setItem('sociedadES','1');
              localStorage.setItem('sociedadAR','0');
              localStorage.setItem('sociedadMX','0');
              localStorage.setItem('sociedadCL','0');
              localStorage.setItem('sociedadUY','0');
              localStorage.setItem('sociedadPE','0');*/

              localStorage.setItem('regionES','ES');
              localStorage.setItem('regionAR','');
              localStorage.setItem('regionMX','');
              localStorage.setItem('regionCL','');
              localStorage.setItem('regionCO','');
              localStorage.setItem('regionUY','');
              localStorage.setItem('regionPE','');
              localStorage.setItem('regionMX','');
              break; 
            } 
            case 'AR': { 
              /*localStorage.setItem('sociedadES','0');
              localStorage.setItem('sociedadAR','1');
              localStorage.setItem('sociedadMX','0');
              localStorage.setItem('sociedadCL','0');
              localStorage.setItem('sociedadUY','0');
              localStorage.setItem('sociedadPE','0'); */

              localStorage.setItem('regionES','');
              localStorage.setItem('regionAR','AR');
              localStorage.setItem('regionMX','');
              localStorage.setItem('regionCL','');
              localStorage.setItem('regionCO','');
              localStorage.setItem('regionUY','');
              localStorage.setItem('regionPE','');
              localStorage.setItem('regionMX','');
              break; 
            } 
            case 'CO': { 
              /*localStorage.setItem('sociedadES','0');
              localStorage.setItem('sociedadAR','0');
              localStorage.setItem('sociedadMX','1');
              localStorage.setItem('sociedadCL','0');
              localStorage.setItem('sociedadUY','0');
              localStorage.setItem('sociedadPE','0');*/

              localStorage.setItem('regionES','');
              localStorage.setItem('regionAR','');
              localStorage.setItem('regionMX','');
              localStorage.setItem('regionCL','');
              localStorage.setItem('regionCO','CO');
              localStorage.setItem('regionUY','');
              localStorage.setItem('regionPE','');
              localStorage.setItem('regionMX','');
              break; 
            }
            case 'CL': { 
             /* localStorage.setItem('sociedadES','0');
              localStorage.setItem('sociedadAR','0');
              localStorage.setItem('sociedadMX','0');
              localStorage.setItem('sociedadCL','1'); 
              localStorage.setItem('sociedadUY','0');
              localStorage.setItem('sociedadPE','0');*/

              localStorage.setItem('regionES','');
              localStorage.setItem('regionAR','');
              localStorage.setItem('regionMX','');
              localStorage.setItem('regionCL','CL');
              localStorage.setItem('regionCO','');
              localStorage.setItem('regionUY','');
              localStorage.setItem('regionPE','');
              localStorage.setItem('regionMX','');
              break; 
            }
            case 'UY': { 
              /*localStorage.setItem('sociedadES','0');
              localStorage.setItem('sociedadAR','0');
              localStorage.setItem('sociedadMX','0');
              localStorage.setItem('sociedadCL','0'); 
              localStorage.setItem('sociedadUY','1');
              localStorage.setItem('sociedadPE','0');*/

              localStorage.setItem('regionES','');
              localStorage.setItem('regionAR','');
              localStorage.setItem('regionMX','');
              localStorage.setItem('regionCL','');
              localStorage.setItem('regionCO','');
              localStorage.setItem('regionUY','UY');
              localStorage.setItem('regionPE','');
              localStorage.setItem('regionMX','');
              break; 
            }
            case 'PE': { 
              /*localStorage.setItem('sociedadES','0');
              localStorage.setItem('sociedadAR','0');
              localStorage.setItem('sociedadMX','0');
              localStorage.setItem('sociedadCL','0'); 
              localStorage.setItem('sociedadUY','0');
              localStorage.setItem('sociedadPE','1');*/

              localStorage.setItem('regionES','');
              localStorage.setItem('regionAR','');
              localStorage.setItem('regionMX','');
              localStorage.setItem('regionCL','');
              localStorage.setItem('regionCO','');
              localStorage.setItem('regionUY','');
              localStorage.setItem('regionPE','PE');
              localStorage.setItem('regionMX','');
              break; 
            }
            case 'MX': { 
              /*localStorage.setItem('sociedadES','0');
              localStorage.setItem('sociedadAR','0');
              localStorage.setItem('sociedadMX','0');
              localStorage.setItem('sociedadCL','0'); 
              localStorage.setItem('sociedadUY','0');
              localStorage.setItem('sociedadPE','1');*/

              localStorage.setItem('regionES','');
              localStorage.setItem('regionAR','');
              localStorage.setItem('regionMX','');
              localStorage.setItem('regionCL','');
              localStorage.setItem('regionCO','');
              localStorage.setItem('regionUY','');
              localStorage.setItem('regionPE','');
              localStorage.setItem('regionMX','MX');
              break; 
            }
            default: { 
             /* localStorage.setItem('sociedadES','1');
              localStorage.setItem('sociedadAR','0');
              localStorage.setItem('sociedadMX','0');
              localStorage.setItem('sociedadCL','0');
              localStorage.setItem('sociedadUY','0');
              localStorage.setItem('sociedadPE','0');*/

              localStorage.setItem('regionES','ES');
              localStorage.setItem('regionAR','');
              localStorage.setItem('regionMX','');
              localStorage.setItem('regionCL','');
              localStorage.setItem('regionCO','');
              localStorage.setItem('regionUY','');
              localStorage.setItem('regionPE','');
              localStorage.setItem('regionMX','');
              break; 
            } 
         } 
          localStorage.setItem('reloginFilter', 'yes');
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('sociedad', JSON.stringify(user.sociedad));
          this.authenticated = true;

          
          //console.log('AuthenticationService->authenticate()->user: ', user);
          return true;
        }
        return false;
      }));
  }

  logout(): Observable<boolean> {
    let user = JSON.parse(localStorage.getItem('user'));
    let formData = { username: user.username, token: user.token };

    const urlParams = Object.entries(formData)
      .map(([key, val]) => {
        return `${key}=${val}`;
      })
      .join('&');

    let url = this.baseUrl + 'api/user/logout?' + urlParams;

    return this.http.get<any>(url)
      .pipe(map(resp => {
        if (resp.response.status === 1) {
          // remove user from local storage to log user out
          localStorage.removeItem('user');
          this.authenticated = false;
          return true;
        }

        return false;
      }));
  }


  isAuthenticated(): boolean {
    return this.authenticated;
  }



}
