import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchFilter } from '../_models/search_filter';
import { User } from '../_models/user';
import { environment } from 'src/environments/environment';
import { SearchDossier } from '../_models/search_dossier';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl: string = environment.clippingBaseUrl;
  user: User;
  search: string = '';
  tipoNoticia: number = 0;
  keywords: string;
  detail=false;
  selectedSearchFilter: SearchFilter;
  newsType: Number;
  medios:string;

  pdf_name:string;
  pdf_description:string;

  selectedNews:Array<any>=[];
  selectedNewsFinalSend:Array<any>=[];
  selectedNewsObject: Array<any>=[];
  selectedNewsObjectPrensa: Array<any>=[];
  selectedNewsObjectOnline: Array<any>=[];
  selectedNewsObjectRadio: Array<any>=[];
  selectedNewsObjectTv: Array<any>=[];


  constructor(private http: HttpClient) {
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'));

      //Envío de filtros a SidebarComponent
      this.getSearchFilters().subscribe((filters) => {
        //console.log('UserService->constructor->getSearchFilters', filters);
        this.getSearchFiltersSendMessage(filters);
      });
    }

  }


  getSearchFilters(): Observable<[SearchFilter]> {
    //console.log(this.baseUrl + '/user/' + this.user.id + '/getSearchFilters');
    let url = this.baseUrl + 'api/user/' + this.user.id + '/getSearchFilters';
    return this.http.get<any>(url)
      .pipe(map(resp => {
        return resp.response.data.map((newsItem) => {
          //Conversión de keywords en texto a Array de Strings
          if(newsItem!=null){
            if(newsItem.keywords != null){
              newsItem.keywords = newsItem.keywords.split(';');
            }
            
          }
          
          return newsItem;
        });
      }));
  }

  getSearchDossier(): Observable<[SearchDossier]> {
    //console.log(this.baseUrl + '/user/' + this.user.id + '/getSearchDossier');
    let url = this.baseUrl + 'api/user/' + this.user.id + '/getSearchDossiers';
    return this.http.get<any>(url)
      .pipe(map(resp => {
        return resp.response.data.map((newsItem) => {
          //Conversión de keywords en texto a Array de Strings
          return newsItem;
        });
      }));
  }
  

  setUser(user: User) {
    this.user = user;
  }

  saveSearchFilter(searchFilter: SearchFilter): Observable<boolean> {
    let url = this.baseUrl + 'api/user/saveSearchFilter';
   
      let postData = {
      id: searchFilter.id,
      user_id: searchFilter.user_id,
      name: searchFilter.name,
      keywords: searchFilter.keywords.join(';'),
      description:searchFilter.description,
      start_date:searchFilter.start_date,
      end_date:searchFilter.end_date,
      notification:searchFilter.notification,
      notification_type:searchFilter.notification_type,
      notification_day:searchFilter.notification_day,
      medios:searchFilter.medios.toString(),
      region:searchFilter.region.toString(),
      restrictiva:searchFilter.restrictiva
    };

    return this.http.post<any>(url, postData)
      .pipe(map(resp => {
        return (resp.response.status === 1);
      }));
  }

  logActionDownload(id_new): Observable<boolean>{
    console.log("log Action");
    let url = this.baseUrl + 'api/user/logAction';
    let postData = {
      id_new: id_new,
      user_id:this.user.id,
    };
    let resp= this.http.post<any>(url, postData)
      .pipe(map(resp => {
        return (resp.response.status);
      }));
      console.log(resp);
      return resp;
  }

  generatePdf(): Observable<boolean> {
    console.log("envia a generar el pdf");
    let url = this.baseUrl + 'api/user/createpdf';
    let postData = {
      news: this.selectedNewsFinalSend,
      user_id:this.user.id,
      pdf_name:this.pdf_name,
      pdf_description:this.pdf_description
    };

    let resp= this.http.post<any>(url, postData)
      .pipe(map(resp => {
        if(resp.response.data.file!=''){
          window.open(this.baseUrl+'storage/files/news/pdf/'+resp.response.data.file, '_blank');
        }
        
        return (resp.response.status, resp.response.data.file);
      }));
      return resp;
  }

  deleteSearchFilter(filterToRemove: SearchFilter): Observable<boolean> {
    let url = this.baseUrl + 'api/user/deleteSearchFilter/' + filterToRemove.id;

    return this.http.get<any>(url)
      .pipe(map(resp => {
        return (resp.response.status === 1);
      }));
  }


  selectSearchFilter(searchFilter: SearchFilter): void {
    /* console.log('UserService->selectSearchFilter(param): ', searchFilter);
    console.log('UserService->selectSearchFilter(): ', this.selectedSearchFilter); */
    
    if(searchFilter){
      console.log("entra asearch select filter:"+searchFilter);
    }
    this.selectedSearchFilter = searchFilter;
    this.keywords = this.selectedSearchFilter.keywords.join(';');
    this.searchFilterSelectedSendMessage(searchFilter);
  }



  /***  Observable to send Changes */
  private getSearchFiltersSubject = new Subject<SearchFilter[]>();
  private selectFilterSelectedSubject = new Subject<SearchFilter>();


  getSearchFiltersSendMessage(filters: SearchFilter[]): void {
    this.getSearchFiltersSubject.next(filters);
  }

  getSearchFiltersClearMessage(): void {
    this.getSearchFiltersSubject.next();
  }

  getSearchFiltersGetMessage(): Observable<SearchFilter[]> {
    return this.getSearchFiltersSubject.asObservable();
  }


  searchFilterSelectedSendMessage(searchFilter: SearchFilter): void {
    //console.log('UserService->searchFilterSelectedSendMessage(): ', searchFilter);
    
    this.selectFilterSelectedSubject.next(searchFilter);
  }
  searchFilterSelectedGetMessage(): Observable<SearchFilter> {
    return this.selectFilterSelectedSubject.asObservable();
  }
  searchFilterSelectedClearMessage(): void {
    this.selectFilterSelectedSubject.next();
  }
  getKeywords(){
    return this.keywords;
  }
  setMedios(medios:string[]){
    this.medios=medios.toString();
  }
  getMedios(){
    return this.medios;
  }
  setSelectedNews(id:string,news){
    if(this.selectedNews.indexOf(id)!==-1){
      this.selectedNewsObject.splice(this.selectedNews.indexOf(id), 1);
      this.selectedNews.splice(this.selectedNews.indexOf(id), 1);
      switch (news.tipo_noticia_id) {
        case 1:
          this.selectedNewsObjectPrensa.splice(this.selectedNewsObjectPrensa.indexOf(news), 1);
          break;
      
        case 2:
          this.selectedNewsObjectTv.splice(this.selectedNewsObjectTv.indexOf(news), 1);
          break;

        case 3:
          this.selectedNewsObjectRadio.splice(this.selectedNewsObjectRadio.indexOf(news), 1);
          break;

        case 4:
          this.selectedNewsObjectOnline.splice(this.selectedNewsObjectOnline.indexOf(news), 1);
          break;   
      }
      
    }else{
      this.selectedNews.push(id.toString());
      this.selectedNewsObject.push(news);
      switch (news.tipo_noticia_id) {
        case 1:
          this.selectedNewsObjectPrensa.push(news);
          break;
      
        case 2:
          this.selectedNewsObjectTv.push(news);
          break;

        case 3:
          this.selectedNewsObjectRadio.push(news);
          break;

        case 4:
          this.selectedNewsObjectOnline.push(news);
          break;   
      }
    }
    
  }
  getSelectedNews(){
    return this.selectedNews;
  }

  getSelectedNewsObject(){
    return this.selectedNewsObject;
  }

  isNewSelected(id:String){
    if(jQuery.inArray(id, this.selectedNews) !== -1){
      return true;
    }else{
      return false;
    }
  }

  resetSelectedNews(){
    this.selectedNews=[];
    this.selectedNewsObject=[];
    this.selectedNewsObjectPrensa=[];
    this.selectedNewsObjectTv=[];
    this.selectedNewsObjectRadio=[];
    this.selectedNewsObjectOnline=[];
    this.selectedNewsFinalSend=[];
  }
  
}
