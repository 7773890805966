import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from 'src/app/_services/user.service';
import { NewsService } from '../_services/news.service';
import { News } from '../_models/news';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  @Input()
  newsItem: News;

  NOTICIA_PRENSA: number = 1;
  NOTICIA_TELEVISION: number = 2;
  NOTICIA_RADIO: number = 3;
  NOTICIA_ONLINE: number = 4;

  baseUrl: string = '';
  prensaMediaUrl: string;
  onlineMediaUrl: string;
  tvMediaUrl: string;
  radioMediaUrl: string;
  removeDuplicateWords = s => s.replace(/(\b\S.+\b)(?=.*\1)/g, "");

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private newsService: NewsService,
    private location: Location,
    private router: Router,
  ) {

    this.baseUrl = this.newsService.baseUrl;
    this.prensaMediaUrl = this.baseUrl + 'storage/files/news/prensa/';
    this.onlineMediaUrl = this.baseUrl + 'storage/files/news/online/';
    this.tvMediaUrl = this.baseUrl + 'storage/files/news/tv/';
    this.radioMediaUrl = this.baseUrl + 'storage/files/news/radio/';
  }

  ngOnInit() {
    //console.log('isSearchInDetail?: ',  this.userService.search);
    this.userService.detail=true;
    this.newsService.applyFilter=1;
    this.getNewsDetail();

  }
  getNewsDetail(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    //console.log(' has news loaded?', this.newsService.hasNewsLoaded);

    if (this.newsService.hasNewsLoaded){
      this.newsItem = this.newsService.getNewsItem(id);
    }
    else {
      this.newsService.getNewsItemCall(id)
        .subscribe(newsItem => this.newsItem = newsItem );
    }
  }

  goBack(): void {
    if(this.newsService.hasNewsLoaded){
     // console.log(this.location.back);
      this.location.back();
    }else{
      this.router.navigateByUrl('/news');
    }
    
  }
  save(url,name){
    //var FileSaver = require('file-saver');
    saveAs.saveAs(url, name);
  }

}
