import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Servicio para controlar la redirección de rutas para usuarios no autenticados
 */
export class AuthGuardService implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {


    if (this.authenticationService.isAuthenticated()) {
      if(localStorage.getItem('reloginFilter')==null){
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      }
      return true;
    }
    
    
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});

    
    

    return false;
  }

}
