import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SearchFilter } from 'src/app/_models/search_filter';
import { UserService } from 'src/app/_services/user.service';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NewsService } from 'src/app/_services/news.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MzModalComponent, MzToastService } from 'ngx-materialize';

/**
 * Nav sidebar materialize functions
 */
/* declare function isSidebarInitialized(): boolean;
declare function isSidebarOpen(): boolean;
declare function closeSidebar(): void;
declare function instanceSidebar(): void;
declare function openSidebar(): void;
 */
/**
 * FILTER MODAL FUNCTIONS
 */
/* declare function instanceFilterModal(): void;
declare function isFilterModalInitialized(): boolean;
declare function closeFilterModal(): void; */



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @ViewChild('bottomSheetModal') bottomSheetModal: MzModalComponent;
  @ViewChild('bottomDeleteModal') bottomDeleteModal: MzModalComponent;
  @ViewChild('eliminar') eliminar;

  originalSearchFilters: SearchFilter[];
  editableSearchFilters: SearchFilter[];
  filterToRemove: any;
  currentSearchFilterEdit: SearchFilter;
  currentSearchFilterLoad: SearchFilter;
  edit: boolean = false;
  editIndex: number;
  search: string = '';
  restrictiva: boolean;

  private subscription: Subscription;
  public toggleIcon: String = 'open';


  public modalOptions: Materialize.ModalOptions = {
    dismissible: false, // Modal can be dismissed by clicking outside of the modal
    opacity: .5, // Opacity of modal background
    inDuration: 300, // Transition in duration
    outDuration: 200, // Transition out duration
    //startingTop: '100%', // Starting top style attribute
    //endingTop: '10%', // Ending top style attribute
    ready: (modal, trigger) => { // Callback for Modal open. Modal and trigger parameters available.
      //console.log(modal, trigger);
      //focus en input: nombre de filtro 
      modal[0].querySelector('input').focus();
    },
    complete: () => { console.log('Closed'); } // Callback for Modal close
  };
 


  constructor(
    private userService: UserService,
    private newsService: NewsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastService: MzToastService
  ) {

  }

  selectRegionES=localStorage.getItem('regionES');
  selectRegionAR=localStorage.getItem('regionAR');
  selectRegionCL=localStorage.getItem('regionCL');
  selectRegionCO=localStorage.getItem('regionCO');
  selectRegionUY=localStorage.getItem('regionUY');
  selectRegionPE=localStorage.getItem('regionPE');
  selectRegionMX=localStorage.getItem('regionMX');
  /** BEGIN Component Events */
  ngOnInit() {
    //console.log('SidebarComponent->ngOnInit');

    this.subscription = this.userService.getSearchFiltersGetMessage()
      .subscribe((filters) => {
        //console.log('SidebarComponent->ngOnInit->subscribe', filters);
        this.originalSearchFilters = filters;
        this.editableSearchFilters = filters;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  /** END Component Events */


  /** Events */
  onKeydown(event) {
    if (event.key === "Enter") {
      this.addKeyword(event.target.value);
      event.target.value = '';
    }
  }


  logout() {
    document.body.style.overflowY = "auto";
    this.authenticationService.logout()
      .subscribe((loggedOut) => {
        if (loggedOut) {
          this.router.navigateByUrl('/login');
        }
      });
  }


  /** Keywords  */
  addKeyword(keyword: string) {
    if (!this.currentSearchFilterEdit)
      this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],'','',null,'','','','',this.restrictiva);
    this.currentSearchFilterEdit.keywords.push(keyword.trim());
  }
  removeKeyword(index) {
    if (this.currentSearchFilterEdit.keywords[index]) {
      this.currentSearchFilterEdit.keywords.splice(index, 1);
    }
  }



  /** Edit Functions */
  editSearchFilter(searchFilter: SearchFilter) {
    this.edit = true;
    if (this.currentSearchFilterEdit)
      this.cancelEditSearchFilter();
    this.currentSearchFilterEdit = searchFilter;
    //this.editIndex = this.originalSearchFilters.indexOf(searchFilter);
    this.editableSearchFilters = this.originalSearchFilters.filter(filter => filter !== searchFilter);
    //console.log('currentSearchFilterEdit: ', this.currentSearchFilterEdit);
  }

  cancelEditSearchFilter() {
    this.edit = false;
    this.currentSearchFilterEdit = null;
    this.editableSearchFilters = this.originalSearchFilters;
  }

  updateSearchFilter() {
    this.currentSearchFilterEdit.name = this.currentSearchFilterEdit.name.trim();
    this.userService.saveSearchFilter(this.currentSearchFilterEdit)
      .subscribe(updated => {
        if (!updated){
          this.toastService.show('No se ha podido actualizar el filtro', 5000);
          throw new Error('No se ha podido actualizar el filtro');
        }
        /*         if (!this.originalSearchFilters[this.editIndex])
                  throw new Error('Error al actualizar la colección original'); */
        this.originalSearchFilters = this.originalSearchFilters.filter(filter => filter !== this.currentSearchFilterEdit);
        this.originalSearchFilters.unshift(this.currentSearchFilterEdit);
        this.toastService.show('Filtro guardado correctamente', 5000, 'green');
        this.originalSearchFilters[this.editIndex] = this.currentSearchFilterEdit;
        this.editableSearchFilters = this.originalSearchFilters;
        this.currentSearchFilterEdit = null;
        this.edit = false;
      });
  }

  /** Create Functions */
  cancelCreateSearchFilter() {
    this.currentSearchFilterEdit = null;
  }
  createSearchFilter() {
    this.currentSearchFilterEdit.name = this.currentSearchFilterEdit.name.trim();
    //console.log('new filter: ', this.currentSearchFilterEdit);

    
    this.userService.saveSearchFilter(this.currentSearchFilterEdit)
      .subscribe((created) => {
        if (!created){
          this.toastService.show('No se ha podido crear el filtro', 5000);
          throw new Error('No se ha podido crear el filtro');
        }
        
        this.toastService.show('Filtro guardado correctamente', 5000, 'green');
        this.currentSearchFilterEdit = null;
        
        this.userService.getSearchFilters().subscribe((searchFilters) => {
          this.originalSearchFilters = searchFilters;
          this.editableSearchFilters = this.originalSearchFilters;
        })
        //this.originalSearchFilters.unshift(this.currentSearchFilterEdit);
      });
  }

  openModalDelete(filterToRemove: SearchFilter){    
    this.bottomDeleteModal.openModal();
    let element: HTMLElement = document.getElementById('eliminar') as HTMLElement;    
   element.addEventListener( 'click', ( e )=>{ 
     this.userService.deleteSearchFilter(filterToRemove)
      .subscribe((deleted) => {
        //console.log(deleted);
        if (!deleted) {
          //this.toastService.show('No se ha podido eliminar el filtro', 5000);
          throw new Error('No se ha podido borrar el filtro');
        }

        this.toastService.show('Filtro eliminado correctamente', 4000, 'green');
        
        this.originalSearchFilters = this.originalSearchFilters.filter(searchFilter => searchFilter !== filterToRemove);
        this.editableSearchFilters = this.originalSearchFilters;
        
      })
     
 //element.removeEventListener('click',(e)=>{ console.log('aaa')});
   });
  
  }

  /** Delete Functions */
  /*deleteSearchFilter(filterToRemove: SearchFilter) {
    console.log('deleteSearchFilter: ', filterToRemove);
    this.userService.deleteSearchFilter(filterToRemove)
      .subscribe((deleted) => {
        if (!deleted) {
          this.toastService.show('No se ha podido eliminar el filtro', 5000);
          throw new Error('No se ha podido borrar el filtro');
        }

        this.toastService.show('Filtro eliminado correctamente', 4000, 'green');

        this.originalSearchFilters = this.originalSearchFilters.filter(searchFilter => searchFilter !== filterToRemove);
        this.editableSearchFilters = this.originalSearchFilters;
      });
  }*/


  selectSearchFilter(searchFilter: SearchFilter) {
    this.userService.selectSearchFilter(JSON.parse(JSON.stringify(searchFilter)));
    //this.userService.selectSearchFilter(Object.assign({}, searchFilter));
    this.newsService.searchByFilter(this.userService.tipoNoticia, searchFilter);
    /*     closeSidebar(); */
    this.router.navigate(['news']);
  }

  onKeydownFilterName(event) {
    if (event.key === "Enter") {
      if (!this.edit)
        this.createSearchFilter();
      else
        this.updateSearchFilter();

      this.bottomSheetModal.close;
    }
  }
  reloadFilters(){
    this.subscription =this.userService.getSearchFilters().subscribe((searchFilters) => {
      this.originalSearchFilters = searchFilters;
      this.editableSearchFilters = this.originalSearchFilters;
    })
  }

  closeFilterNav(){
    console.log("closed");
  }

  activeRegionES(){
    if(this.selectRegionES==''){
      this.selectRegionES='ES';
      localStorage.setItem('regionES','ES');
    }else{
      this.selectRegionES='';
      localStorage.setItem('regionES','');
    }
    if(this.userService.selectedSearchFilter){
      this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
    }else{
      this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
    }
    
  }
  activeRegionAR(){
    if(this.selectRegionAR==''){
      this.selectRegionAR='AR';
      localStorage.setItem('regionAR','AR');
    }else{
      this.selectRegionAR='';
      localStorage.setItem('regionAR','');
    }
    if(this.userService.selectedSearchFilter){
      this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
    }else{
      this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
    }
  }
  activeRegionCL(){
    if(this.selectRegionCL==''){
      this.selectRegionCL='CL';
      localStorage.setItem('regionCL','CL');
    }else{
      this.selectRegionCL='';
      localStorage.setItem('regionCL','');
    }    
    if(this.userService.selectedSearchFilter){
      this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
    }else{
      this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
    }
  }
  activeRegionCO(){
    if(this.selectRegionCO==''){
      this.selectRegionCO='CO';
      localStorage.setItem('regionCO','CO');
    }else{
      this.selectRegionCO='';
      localStorage.setItem('regionCO','');
    }
    if(this.userService.selectedSearchFilter){
      this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
    }else{
      this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
    }
  }
  activeRegionUY(){
    if(this.selectRegionUY==''){
      this.selectRegionUY='UY';
      localStorage.setItem('regionUY','UY');
    }else{
      this.selectRegionUY='';
      localStorage.setItem('regionUY','');
    }
    if(this.userService.selectedSearchFilter){
      this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
    }else{
      this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
    }
  }
  activeRegionMX(){
    if(this.selectRegionMX==''){
      this.selectRegionMX='MX';
      localStorage.setItem('regionMX','MX');
    }else{
      this.selectRegionMX='';
      localStorage.setItem('regionMX','');
    }
    if(this.userService.selectedSearchFilter){
      this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
    }else{
      this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
    }
  }
  activeRegionPE(){
    if(this.selectRegionPE==''){
      this.selectRegionPE='PE';
      localStorage.setItem('regionPE','PE');
    }else{
      this.selectRegionPE='';
      localStorage.setItem('regionPE','');
    }
    if(this.userService.selectedSearchFilter){
      this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
    }else{
      this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
    }
  }
}
