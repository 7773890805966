import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {

  //@Input() preferenceKeywords: String[];
  preferenceKeywords: String[] = [
    'noche',
    'casa'
  ];

  constructor() { }

  ngOnInit() {
  }

}
