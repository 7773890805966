import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewsComponent } from './news/news.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DetailComponent } from './detail/detail.component';
import { AuthGuardService } from './_services/guards/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { PageMasterComponent } from './common/page-master/page-master.component';
import { FiltersComponent } from './filters/filters.component';
import { BoletinesComponent } from './boletines/boletines.component';


const routes: Routes = [
  { path: '', redirectTo: 'news', pathMatch: 'full' },
  {
    path: 'news', component: PageMasterComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'all', pathMatch: 'full' },
      { path: 'all', component: NewsComponent },
      { path: 'online', component: NewsComponent },
      { path: 'prensa', component: NewsComponent },
      { path: 'tv', component: NewsComponent },
      { path: 'radio', component: NewsComponent, },
      { path: 'detail/:id', component: DetailComponent },
      { path: 'filtros', component: FiltersComponent },
      { path: 'dossieres', component: BoletinesComponent },
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
