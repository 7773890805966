import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { UserService } from '../_services/user.service';
import { News } from '../_models/news';
import { SearchFilter } from '../_models/search_filter';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  //apiUrl: string = 'https://clipping.prhge.com/api/news/search';
  //apiUrl: string = 'http://clipping-laravel.local/api';
  baseUrl: string = environment.clippingBaseUrl;
  newsTypes: Array<Number> = [
    1, 2, 3, 4
  ];

  user: User;

  public total_prensa;
  public total_online;
  public total_radio;
  public total_tv;

  public primer_total=false;
  public primer_total_envio=false;
  public reset_totals=false;

  public newsCollection: Array<News> = [];
  private searchBool: boolean = false;
  public searchChanged: Subject<boolean>;
  page: number = 1;
  results: number = 20;
  applyFilter=1;
  
  search_temp:String="";
  keywords_temp:Array<String>=[];

  constructor(private http: HttpClient,private userService: UserService,) {
    this.searchChanged = new Subject();
  }

  searchIsEmpty(){
    console.log("entra a search empty");
    this.searchChanged.next(!this.searchBool);
  }

  apiGetNews(newsType: Number, search?: String, keywords?: String[],startDate?:String,endDate?:String, medios?:String,restrictiva?:boolean): Observable<News[]> {
   
    let types = (this.newsTypes.indexOf(newsType) !== -1) ? [newsType] : this.newsTypes;
    
   let region=localStorage.getItem('regionES')+","+localStorage.getItem('regionAR')+","+localStorage.getItem('regionCL')+","+localStorage.getItem('regionCO')+","+localStorage.getItem('regionUY')+","+localStorage.getItem('regionPE')+","+localStorage.getItem('regionMX');

   if(!this.primer_total){

    if(this.keywords_temp.toString()!=''){
      this.primer_total=true;
    }else{
      this.primer_total=false;
    }

    if(this.reset_totals==true){
      this.primer_total=false;
      this.primer_total_envio=false;
    }
     
  }
  let id;
  if (localStorage.getItem('user')) {
  this.user = JSON.parse(localStorage.getItem('user'));
  id = this.user.id;
  }

    let postData = {
      'tiposNoticia': types,
      'search': search ? search : '',
      'dateFrom': startDate ? startDate : '',
      'dateTo': endDate ? endDate : '',
      'page': this.page,
      'results': this.results,
      'keywords': keywords ? keywords : '',
      'region':region,
      'medios':medios ? medios:'',
      'restrictiva': restrictiva ? restrictiva : false,
      'totals':this.primer_total,
      'userId': id
    };  
    this.newsCollection=[];
    console.log(postData);
    console.log("---->temp k "+this.keywords_temp);
    console.log("---->ser k "+this.search_temp);
    if(postData.keywords.length==0){
      postData.medios="Prensa,Online,Televisión,Radio";
    }

    const urlParams = Object.entries(postData)
      .map(([key, val]) => {
        if (Array.isArray(val)) {
          const params = val.join(`&${key}%5B%5D=`);
          return `${key}%5B%5D=` + params;
        }
        return `${key}=${val}`;
      })
      .join('&');

    let url = this.baseUrl + 'api/news/search?' + urlParams;
    document.getElementById("loading-container2").style.display="block";
   // console.log('api/news/search?' + urlParams);

    let headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    return this.http.get<any>(url)
      .pipe(map(resp => {
        console.log(resp.response.data);
        if(!this.primer_total_envio){

          if(this.keywords_temp.toString()!=''){
            this.primer_total_envio=true;
            this.total_online=resp.response.data[1].filter(value => value.tipo_noticia_id == '4').length;  
            this.total_prensa=resp.response.data[1].filter(value => value.tipo_noticia_id == '1').length;  
            this.total_radio=resp.response.data[1].filter(value => value.tipo_noticia_id == '3').length;  
            this.total_tv=resp.response.data[1].filter(value => value.tipo_noticia_id == '2').length;
          }else{
            this.primer_total_envio=false;
          }
          document.getElementById("loading-container2").style.display="none";
        }
         //console.log(resp.response.data[0].filter(value => value.tipo_noticia_id == '4').length);
         this.reset_totals=false;
        this.newsCollection = resp.response.data[0];
        return resp.response.data[0];
      }));
  }



  searchByText(newsType: Number, search: String, page?: number) {
    if(search.length>0){
      search = search.replace(' ','%25');
      this.search_temp=search;
    }
    this.primer_total=false;
    this.reset_totals=true;
    this.page = (page) ? page : 1;
    console.log("entra a: searchByText");
    //get data
    this.apiGetNews(newsType, this.search_temp)
      .subscribe((newsCollection) => {
        //send message
        if(newsCollection.length > 0){
        document.getElementById("no-items").innerHTML='';
        this.newsSearchedSendMessage(newsCollection);
        }else{
          search = search.replace('%25',' ');
          this.newsSearchedSendMessage(newsCollection);
         // console.log(page);
          if(!page){
           // console.log("eeeentra");
            if(search!=''){
              document.getElementById("no-items").innerHTML= "<p style='font-size:1.3em;'>La búsqueda: "+search+" no ha coincidido con ninguna noticia</hp>";
            }else{
              if(newsCollection.length==0){
                if(page==undefined){
                  document.getElementById("no-items").innerHTML= "<p style='font-size:1.3em;'>No se ha encontrado ninguna noticia</hp>";
      
                }

              }            }
          }
        }
      });
  }

  searchByTextInFilter(newsType: Number, search: String, page?: number) {

    search = search.replace(' ','%25');
    //console.log(search);
    this.page = (page) ? page : 1;
    //get data
    
  }

  searchByFilter(newsType: Number, searchFilter: SearchFilter, page?: number) {
    if( searchFilter.keywords.length>0){
      console.log("no fa push de key");
      this.primer_total=false;
      this.reset_totals=true;
      this.keywords_temp= searchFilter.keywords;
    }else{
      if(this.search_temp.length>0){
        if (this.keywords_temp.indexOf(this.search_temp) === -1) {
          this.keywords_temp.push(this.search_temp);
        }
      }
    }
    this.userService.search="";
    this.userService.newsType=newsType;
    this.page = (page) ? page : 1;
    //get data
    console.log("entra a: searchByFilter->"+searchFilter.end_date);
    this.apiGetNews(newsType, undefined, this.keywords_temp,searchFilter.start_date,searchFilter.end_date,searchFilter.medios,searchFilter.restrictiva)
      .subscribe((newsCollection) => {
        //send message
        this.newsSearchedSendMessage(newsCollection);
        if(newsCollection.length==0){
          if(page==undefined){
            document.getElementById("no-items").innerHTML= "<p style='font-size:1.3em;'>No se ha encontrado ninguna noticia</hp>";

          }
        }else{
          document.getElementById("no-items").innerHTML="";
        }
      });
  }

  searchByFilterSidebar(newsType: Number, searchFilter: SearchFilter,medios?:String, page?: number) {
   // console.log(medios);
   console.log("entra a: searchByFilterSidebar");
   this.primer_total=false;
   this.primer_total_envio=false;
   this.reset_totals=true;
   console.log(this.primer_total);
    this.userService.search="";
    this.userService.newsType=newsType;
    this.page = (page) ? page : 1;
    if(searchFilter.keywords.length==0){
      searchFilter.keywords=[''];
    }
    //get data
    this.apiGetNews(newsType, undefined, searchFilter.keywords,searchFilter.start_date,searchFilter.end_date,medios,searchFilter.restrictiva)
      .subscribe((newsCollection) => {
        //send message
        this.newsSearchedSendMessage(newsCollection);
        if(newsCollection.length==0){
          if(page==undefined){
            document.getElementById("no-items").innerHTML= "<p style='font-size:1.3em;'>No se ha encontrado ninguna noticia</hp>";

          }
        }else{
          document.getElementById("no-items").innerHTML="";
        }

      });
  }

  searchByTextSidebar(newsType: Number, search: String,start_date:String,end_date:String,medios?:String[], page?: number) {
    console.log("entra a: searchByTextSidebar");
    search = search.replace(' ','%25');
    //console.log(search);
    this.page = (page) ? page : 1;
    //get data
    this.apiGetNews(newsType, search,undefined,start_date,end_date,medios.toString())
      .subscribe((newsCollection) => {
        //send message
        if(newsCollection.length > 0){
        document.getElementById("no-items").innerHTML='';
        this.newsSearchedSendMessage(newsCollection);
        }else{
          search = search.replace('%25',' ');
          this.newsSearchedSendMessage(newsCollection);
         // console.log(page);
          if(!page){
            if(search!=''){
              document.getElementById("no-items").innerHTML= "<p style='font-size:1.3em;'>La búsqueda: "+search+" no ha coincidido con ninguna noticia</hp>";
            }else{
              if(newsCollection.length==0){
                if(page==undefined){
            document.getElementById("no-items").innerHTML= "<p style='font-size:1.3em;'>No se ha encontrado ninguna noticia</hp>";

          }
              } else{
                document.getElementById("no-items").innerHTML="";
              }
            }
          }
        }
      });
  }


  get hasNewsLoaded(): Boolean {
    return this.newsCollection.length > 0;
  }

  setNewsCollection(news: News[]): void {
    this.newsCollection = news;
  }

  getNewsItem(id: string): News {
    const newsItem = this.newsCollection.find(newsI => (newsI.id === id));
    return newsItem;
  }

  getNewsItemCall(id: string): Observable<News> {
    let headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    const url = this.baseUrl + 'api/news/get/' + id;
    return this.http.get<any>(url)
      .pipe(map(resp => resp.response.data));
  }


  /*** Observable para detectar cambios en noticias */
  private searchSubject = new Subject<News[]>();
  //private byFilterSearchSubject = new Subject<News[]>();

  /** BUSQUEDA POR TEXTO */
  newsSearchedSendMessage(news: News[]): void {
  //  console.log("entra");
    this.searchSubject.next(news);
  }
  newsSearchClearMessage(): void {
    this.searchSubject.next();
  }
  newsSearchGetMessage(): Observable<News[]> {
    return this.searchSubject.asObservable();
  }

  /** BUSQUEDA POR FILTRO DE USUARIO */
  /* 
    byFilterSearchSendMessage(searchFilter: SearchFilter): void {
      this.byFilterSearchSubject.next(searchFilter);
    }
    byFilterSearchGetMessage(): Observable<SearchFilter> {
      return this.byFilterSearchSubject.asObservable();
    }
    byFilterSearchClearMessage(): void {
      this.byFilterSearchSubject.next();
    } */




}
