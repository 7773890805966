import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  //providers: [ AuthenticationService ]
})
export class AppComponent {
  title = 'Clipping de comunicación';

  currentRoute: string = 'all';


  constructor(){}


}
