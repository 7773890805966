import { Component, OnInit, ViewChild,  } from '@angular/core';
import { Location } from '@angular/common';
import { SearchFilter } from '../_models/search_filter';
import { UserService } from 'src/app/_services/user.service';
import { NewsService } from 'src/app/_services/news.service';
import { Subscription,Observable } from 'rxjs';
import { MzModalComponent, MzToastService, MzDatepickerModule, MzValidationModule } from 'ngx-materialize';
import { Router } from '@angular/router';


@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  @ViewChild('createSheetModal') createSheetModal: MzModalComponent;
  @ViewChild('createSheetModalPredefined') createSheetModalPredefined: MzModalComponent;
  @ViewChild('bottomDeleteModal') bottomDeleteModal: MzModalComponent;
  @ViewChild('createDate') createDate: MzDatepickerModule;
  @ViewChild('eliminarFiltro') eliminar;

  originalSearchFilters: SearchFilter[];
  editableSearchFilters: SearchFilter[];
  filterToRemove: any;
  currentSearchFilterEdit: SearchFilter;
  currentSearchFilterLoad: SearchFilter;
  edit: boolean = false;
  editIndex: number;
  tagValue:string = "";
  private subscription: Subscription;
  filtros$: Observable<SearchFilter[]>;
  regions:string[]=['ES','AR','CO','UY','CL','MX']; /*,'PE'*/
  medios:string[]=['Prensa','Online','Radio','Televisión'];
  regionsFull:string[]=['España','Argentina','Colombia','Uruguay','Chile','Mexico']; /*,'PE'*/
  selectedEditRegions:string[]=[];
  medios_seleccionados:string[]=[];
  medio_prensa = true;
  medio_online = true;
  medio_tv = true;
  medio_radio = true;
  restrictiva:boolean;

  filtros_a_mostrar:SearchFilter[];

  page:number=1;
  current_page:number=1;
  items_page:number=5;


  public modalOptions: Materialize.ModalOptions = {
    dismissible: false, // Modal can be dismissed by clicking outside of the modal
    opacity: .5, // Opacity of modal background
    inDuration: 300, // Transition in duration
    outDuration: 200, // Transition out duration
    startingTop: '100%', // Starting top style attribute
    endingTop: '5%', // Ending top style attribute
    ready: (modal, trigger) => { // Callback for Modal open. Modal and trigger parameters available.
    },
    complete: () => { this.resetFormValues(); }, // Callback for Modal close
  
  };
  public modalOptionsPredfined: Materialize.ModalOptions = {
    dismissible: false, // Modal can be dismissed by clicking outside of the modal
    opacity: .5, // Opacity of modal background
    inDuration: 300, // Transition in duration
    outDuration: 200, // Transition out duration
    startingTop: '50%', // Starting top style attribute
    endingTop: '5%', // Ending top style attribute
    ready: (modal, trigger) => { // Callback for Modal open. Modal and trigger parameters available.
    },
    complete: () => { this.resetFormValues(); }, // Callback for Modal close
  
  };

  public options: Pickadate.DateOptions = {
    monthsFull: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		weekdaysFull: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    weekdaysLetter: ["D","L", "M", "M", "J", "V", "S"],
    labelMonthNext: 'Siguiente mes',
		labelMonthPrev: 'Mes anterior',
		labelMonthSelect: 'Selecciona un mes',
		labelYearSelect: 'Selecciona un año',
    clear: 'Cancelar', // Clear button text
    close: 'Aceptar',    // Ok button text
    today: 'Hoy', // Today button text
    firstDay: 1,
    format: 'dd/mm/yyyy',
    formatSubmit: 'dd-mm-yyyy',    
    closeOnSelect:true,
  }; 
  public options_semanal: Pickadate.DateOptions = {
    monthsFull: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		weekdaysFull: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    weekdaysLetter: ["D","L", "M", "M", "J", "V", "S"],
    labelMonthNext: 'Siguiente mes',
		labelMonthPrev: 'Mes anterior',
		labelMonthSelect: 'Selecciona un mes',
		labelYearSelect: 'Selecciona un año',
    clear: 'Cancelar', // Clear button text
    close: 'Aceptar',    // Ok button text
    today: '', // Today button text
    firstDay: 1,
    format: 'dddd',
    formatSubmit: 'dd-mm-yyyy',   
    closeOnSelect:true,
  }; 
  public options_mensual: Pickadate.DateOptions = {
    monthsFull: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		weekdaysFull: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    weekdaysLetter: ["D","L", "M", "M", "J", "V", "S"],
    labelMonthNext: 'Siguiente mes',
		labelMonthPrev: 'Mes anterior',
		labelMonthSelect: 'Selecciona un mes',
		labelYearSelect: 'Selecciona un año',
    clear: 'Cancelar', // Clear button text
    close: 'Aceptar',    // Ok button text
    today: '', // Today button text
    firstDay: 1,
    format: 'dd',
    formatSubmit: 'dd-mm-yyyy',  
    closeOnSelect:true,  
  }; 
  constructor(
    private userService: UserService,
    private newsService: NewsService,
    private location: Location,
    private router: Router,
    private toastService: MzToastService,   
    
  ) {
    this.getAllFilters();
   }

  ngOnInit() {
    
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /** Events */
  onKeydown(event) {
    
    if (event.key === "Enter") {
      if(event.target.value!=''){
        this.addKeyword(event.target.value);
        event.target.value = null;
       }
    }
    if (event.key === ",") {
      if(event.target.value!=''){
        this.addKeyword(event.target.value);
        event.target.value = null;
        return false;
      }else{
        return false;
      }

    }
  }

  onKeydownFilterName(event) {
    
  }
  goBack(): void {
    this.location.back();
  }
  /** Functions */
/*
  getAllFilters(){
    this.subscription =this.userService.getSearchFilters().subscribe((searchFilters) => {
      this.originalSearchFilters = searchFilters;
      this.editableSearchFilters = this.originalSearchFilters;
    })
  }*/

  getAllFilters(){
    this.subscription =this.userService.getSearchFilters().subscribe((searchFilters) => {
     this.originalSearchFilters = searchFilters;
      this.editableSearchFilters = this.originalSearchFilters;
      this.filtros_a_mostrar=[];
      for (let index = 0; index < this.items_page; index++) {
       
        if(this.editableSearchFilters[index]){
          this.filtros_a_mostrar.push(this.editableSearchFilters[index]);
         }  
        
      }

    })
  }

  /*getFiltersDetail(){
    this.subscription = this.userService.getSearchFiltersGetMessage()
      .subscribe((filters) => {
        this.originalSearchFilters = filters;
        this.editableSearchFilters = filters;
        console.log('FiltersComponent->ngOnInit->subscribe', this.editableSearchFilters);
      });
  }*/

  
  openModelFilter(){
    //console.log("open sheet Modal");
    if (!this.currentSearchFilterEdit){
      this.medios_seleccionados=['Prensa','Online','Radio','Televisión'];
      this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],'','',null,'','01-01-2018','',this.medios.toString(),this.restrictiva);
    }
    if(this.currentSearchFilterEdit.notification_day==null){
      this.currentSearchFilterEdit.notification_day='01-01-2018'; 
    }
    
    if(this.currentSearchFilterEdit.description=="Genérico"){
      this.currentSearchFilterEdit.notification=1;
      
      if(this.currentSearchFilterEdit.notification_type==null){
        this.currentSearchFilterEdit.notification_type='diarias';
      }
      this.createSheetModalPredefined.openModal();
    }else{
      console.log(this.currentSearchFilterEdit.description);
      this.createSheetModal.openModal();
    }
   
  }
  openModalDelete(filterToRemove: SearchFilter){   
    this.bottomDeleteModal.openModal();
    
    let element: HTMLElement = document.getElementById('eliminarFiltro') as HTMLElement;    
    element.addEventListener( 'click', ( e )=>{ 
    
      
     this.userService.deleteSearchFilter(filterToRemove)
      .subscribe((deleted) => {
        console.log(deleted);
        if (!deleted) {
          //this.toastService.show('No se ha podido eliminar el filtro', 5000);
          throw new Error('No se ha podido borrar el filtro');
        }

        this.toastService.show('Filtro eliminado correctamente', 4000, 'green');
        
        this.originalSearchFilters = this.originalSearchFilters.filter(searchFilter => searchFilter !== filterToRemove);
        this.editableSearchFilters = this.originalSearchFilters;
        
        this.userService.getSearchFilters().subscribe((searchFilters) => {
          this.originalSearchFilters = searchFilters;
          this.editableSearchFilters = this.originalSearchFilters;
        })
        
      })
      this.resetFormValues();
     
   });
  
  }
  createSearchFilter() {
    this.currentSearchFilterEdit.name = this.currentSearchFilterEdit.name.trim();
    this.userService.saveSearchFilter(this.currentSearchFilterEdit)
      .subscribe((created) => {
        if (!created){
          this.toastService.show('No se ha podido crear el filtro', 5000);
          throw new Error('No se ha podido crear el filtro');
        }
        
        this.toastService.show('Filtro guardado correctamente', 5000, 'green');
        this.currentSearchFilterEdit = null;
        this.selectedEditRegions=[];
        this.medios_seleccionados=[];

        this.userService.getSearchFilters().subscribe((searchFilters) => {
          this.originalSearchFilters = searchFilters;
          this.editableSearchFilters = this.originalSearchFilters;
        })
        //this.originalSearchFilters.unshift(this.currentSearchFilterEdit);
      });
  }
  updateSearchFilter() {
    this.currentSearchFilterEdit.restrictiva=this.restrictiva;
    this.currentSearchFilterEdit.name = this.currentSearchFilterEdit.name.trim();
    this.userService.saveSearchFilter(this.currentSearchFilterEdit)
      .subscribe(updated => {
        if (!updated){
          this.toastService.show('No se ha podido actualizar el filtro', 5000);
          throw new Error('No se ha podido actualizar el filtro');
        }
        this.toastService.show('Filtro guardado correctamente', 5000, 'green');
        this.selectedEditRegions=[];
        this.medios_seleccionados=[];

        this.originalSearchFilters = this.originalSearchFilters.filter(filter => filter !== this.currentSearchFilterEdit);
       // this.originalSearchFilters.unshift(this.currentSearchFilterEdit);
        
        //this.originalSearchFilters[this.editIndex] = this.currentSearchFilterEdit;
        this.editableSearchFilters = this.originalSearchFilters;
        this.currentSearchFilterEdit = null;
        this.edit = false;
        
      });
  }
  

  /** Keywords  */
  addKeyword(keyword: string) {
    if (!this.currentSearchFilterEdit)
    this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],'','',null,'','','','',this.restrictiva);
    this.currentSearchFilterEdit.keywords.push(keyword.trim());
  }
  removeKeyword(index) {
    if (this.currentSearchFilterEdit.keywords[index]) {
      this.currentSearchFilterEdit.keywords.splice(index, 1);
    }
  }

  /** Edit Functions */
  editSearchFilter(searchFilter: SearchFilter) {
    this.medio_prensa=false;
    this.medio_online=false;
    this.medio_radio=false;
    this.medio_tv=false;
    

    this.edit = true;
    if (this.currentSearchFilterEdit)
      this.cancelEditSearchFilter();
    this.currentSearchFilterEdit = searchFilter;
    this.editIndex = this.originalSearchFilters.indexOf(searchFilter);
    if(this.currentSearchFilterEdit.region){
      this.selectedEditRegions=this.currentSearchFilterEdit.region.split(",");

    }
    if(this.currentSearchFilterEdit.medios){
      this.medios_seleccionados=this.currentSearchFilterEdit.medios.split(",");
      this.medios_seleccionados.forEach(element => {
        switch (element) {
          case 'Prensa':
           this.medio_prensa=true;
            break;
            case 'Online':
            this.medio_online=true;
            break;
            case 'Radio':
            this.medio_radio=true;
            break;
            case 'Televisión':
            this.medio_tv=true;
            break;
        }
      });
    }
    this.restrictiva= this.currentSearchFilterEdit.restrictiva;
    
    //console.log("ediit->"+JSON.stringify(this.currentSearchFilterEdit));

    //this.editableSearchFilters = this.originalSearchFilters.filter(filter => filter !== searchFilter);
    //console.log('currentSearchFilterEdit: ', this.currentSearchFilterEdit);

    this.openModelFilter();

  }

  cancelEditSearchFilter() {
    this.edit = false;
    this.currentSearchFilterEdit = null;
    this.selectedEditRegions=[];
    this.medios_seleccionados=[];
    this.medio_prensa = true;
    this.medio_online = true;
    this.medio_tv = true;
    this.medio_radio = true;

    this.editableSearchFilters = this.originalSearchFilters;
    this.getAllFilters();
  }

  /** Form Controls */
  resetFormValues(){
    this.cancelEditSearchFilter();
    }
  selectSearchFilter(searchFilter: SearchFilter) {
    //console.log(searchFilter);
    var regiones_seleccionadas= searchFilter.region;
    var res = regiones_seleccionadas.split(",");
    
    this.regions.forEach(region1=>{
      let element1: HTMLElement = document.getElementById('region_'+region1) as HTMLElement;
      if(element1.className=="region-item active"){
        element1.click();
       // console.log(element);
      }
    });
/* programamos un timeout a la redireccion para que no parpadee al cargar las noticias ya que simulamos el click */
    setTimeout(() => {
      res.forEach(region => {
        let element: HTMLElement = document.getElementById('region_'+region) as HTMLElement;
        //element.getElementsByClassName('active');
       if(element.className!="region-item active"){
         element.click();
         
        // console.log(element);
       }
      });
    }, 50);   
   // this.router.navigate(['news']); 
    setTimeout(() => {
      this.router.navigate(['news']); 
      this.userService.selectSearchFilter(JSON.parse(JSON.stringify(searchFilter)));
     
    }, 100);  
    
    //this.userService.selectSearchFilter(Object.assign({}, searchFilter));
    //this.newsService.searchByFilter(this.userService.tipoNoticia, searchFilter);
    /*     closeSidebar(); */
  }

  validatorForm(type){
    console.log(type);
    this.addMedio();
    this.currentSearchFilterEdit.medios=this.medios_seleccionados.toString();
    if(this.currentSearchFilterEdit.notification_type=="sin" || this.currentSearchFilterEdit.notification_type==null){
      this.currentSearchFilterEdit.notification=0;
    }else{
      this.currentSearchFilterEdit.notification=1;
    }

    if(this.currentSearchFilterEdit.medios=="" || this.currentSearchFilterEdit.name=="" || this.currentSearchFilterEdit.keywords.length==0 ||this.selectedEditRegions.length==0 ||(this.currentSearchFilterEdit.notification==1 && this.currentSearchFilterEdit.notification_type!="diarias" && this.currentSearchFilterEdit.notification_day=="") ){
      if(this.currentSearchFilterEdit.name==""){
        this.toastService.show('El Nombre del filtro es obligatorio.', 5000, 'red');
      }
      if(this.currentSearchFilterEdit.keywords.length==0){
        if(this.tagValue.length>0){
          this.addKeyword(this.tagValue);
          this.tagValue='';
        }else{
          this.toastService.show('Los Tags son obligatorios.', 5000, 'red');
        }
      }
      if(this.selectedEditRegions.length==0){
        this.toastService.show('Mínimo una región seleccionada', 5000, 'red');
      }
      if(this.currentSearchFilterEdit.medios==""){
        this.toastService.show('Mínimo un medio seleccionado', 5000, 'red');
      }
     /* if(this.currentSearchFilterEdit.start_date==""){
        this.toastService.show('La Fecha Inicio es obligatoria.', 5000, 'red');
      }
      if(this.currentSearchFilterEdit.end_date==""){
        this.toastService.show('La Fecha Fin es obligatoria.', 5000, 'red');
      }*/
      if(this.currentSearchFilterEdit.notification_day==""&& this.currentSearchFilterEdit.notification==1){
        this.toastService.show('El Dia de Notificación es obligatorio.', 5000, 'red');
      }
      
    }else{
      if(type=="create"){
        this.currentSearchFilterEdit.region=this.selectedEditRegions.toString();
        this.currentSearchFilterEdit.medios=this.medios_seleccionados.toString();
        this.createSearchFilter();
      }else{
        this.currentSearchFilterEdit.region=this.selectedEditRegions.toString();
        this.currentSearchFilterEdit.medios=this.medios_seleccionados.toString();
        this.updateSearchFilter();
      }
      
      this.createSheetModal.closeModal();
      this.createSheetModalPredefined.closeModal();
    }
  }
  addMedio(){
    this.medios_seleccionados=null;
    this.medios_seleccionados=[];
   
    if(this.medio_prensa){
      this.medios_seleccionados.push("Prensa");
    }
    if(this.medio_online){
      this.medios_seleccionados.push("Online");
    }
    if(this.medio_radio){
      this.medios_seleccionados.push("Radio");
    }
    if(this.medio_tv){
      this.medios_seleccionados.push("Televisión");
    }
    this.userService.setMedios(this.medios_seleccionados);
   //console.log("entra_> "+this.medios_seleccionados);
  }
/*
  private subscribe(): void {
    // console.log("in subscribe");
    this.subscription = this.userService.getSearchFiltersGetMessage()
    .subscribe((filters) => {      
      this.originalSearchFilters = filters;
      this.editableSearchFilters = filters;
      //console.log('FiltersComponent->ngOnInit->subscribe', this.editableSearchFilters);
    });
  }*/

  onPageChange(event){
    
    this.page=event;
    
    let index=(this.page-1)*this.items_page;

    this.filtros_a_mostrar=[];
     for (index; index < this.items_page*this.page; index++) {
      if(this.editableSearchFilters[index]){
       this.filtros_a_mostrar.push(this.editableSearchFilters[index]);
      }   
       
     }
     this.current_page=event;
     console.log(this.current_page);
  }
}

