import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { SearchDossier } from '../_models/search_dossier';
import { UserService } from 'src/app/_services/user.service';
import { NewsService } from 'src/app/_services/news.service';
import { Subscription,Observable } from 'rxjs';
import { MzModalComponent, MzToastService, MzDatepickerModule, MzValidationModule, MzPaginationModule } from 'ngx-materialize';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';


@Component({
  selector: 'app-boletines',
  templateUrl: './boletines.component.html',
  styleUrls: ['./boletines.component.scss']
})



export class BoletinesComponent implements OnInit {

  @ViewChild('createSheetModal') createSheetModal: MzModalComponent;
  @ViewChild('createSheetModalPredefined') createSheetModalPredefined: MzModalComponent;
  @ViewChild('bottomDeleteModal') bottomDeleteModal: MzModalComponent;
  @ViewChild('createDate') createDate: MzDatepickerModule;
  @ViewChild('eliminarFiltro') eliminar;

  originalSearchFilters: SearchDossier[];
  editableSearchFilters: SearchDossier[];
  filterToRemove: any;
  currentSearchFilterEdit: SearchDossier;
  currentSearchFilterLoad: SearchDossier;
  edit: boolean = false;
  editIndex: number;
  baseUrl: string = environment.clippingBaseUrl;
  private subscription: Subscription;
  filtros$: Observable<SearchDossier[]>;

  boletines_a_mostrar:SearchDossier[];

  page:number=1;
  current_page:number=1;
  items_page:number=5;

  selectedEditRegions:string[]=[];

  radioMediaUrl: string=this.baseUrl+"storage/files/news/pdf/";

  constructor(
    private userService: UserService,
    private newsService: NewsService,
    private location: Location,
    private router: Router,
    private toastService: MzToastService,   
    
  ) {
    this.getAllFilters();
   }

  ngOnInit() {
    
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /** Events */
  

  goBack(): void {
    this.location.back();
  }
  /** Functions */

  getAllFilters(){
    this.subscription =this.userService.getSearchDossier().subscribe((searchDossier) => {
     this.originalSearchFilters = searchDossier;
      this.editableSearchFilters = this.originalSearchFilters;
      this.boletines_a_mostrar=[];
      for (let index = 0; index < this.items_page; index++) {
       
        if(this.editableSearchFilters[index]){
          this.boletines_a_mostrar.push(this.editableSearchFilters[index]);
         }  
        
      }

    })
  }

  /*
  getFiltersDetail(){
    this.subscription = this.userService.getSearchFiltersGetMessage()
      .subscribe((filters) => {
        this.originalSearchFilters = filters;
        this.editableSearchFilters = filters;
        console.log('FiltersComponent->ngOnInit->subscribe', this.editableSearchFilters);
      });
  }*/

  
 
  openModalDelete(filterToRemove: SearchDossier){   
    this.bottomDeleteModal.openModal();
    
    let element: HTMLElement = document.getElementById('eliminarFiltro') as HTMLElement;    
    element.addEventListener( 'click', ( e )=>{ 
    
    /*  
     this.userService.deleteSearchFilter(filterToRemove)
      .subscribe((deleted) => {
        console.log(deleted);
        if (!deleted) {
          //this.toastService.show('No se ha podido eliminar el filtro', 5000);
          throw new Error('No se ha podido borrar el filtro');
        }

        this.toastService.show('Filtro eliminado correctamente', 4000, 'green');
        
        this.originalSearchFilters = this.originalSearchFilters.filter(searchFilter => searchFilter !== filterToRemove);
        this.editableSearchFilters = this.originalSearchFilters;
        
        this.userService.getSearchFilters().subscribe((searchFilters) => {
          this.originalSearchFilters = searchFilters;
          this.editableSearchFilters = this.originalSearchFilters;
        })
        
      })*/

     
   });
  
  }

  save(name){
    // var FileSaver = require('file-saver');

     saveAs.saveAs(this.radioMediaUrl+name, name);
   }
   onPageChange(event){
    
     this.page=event;
     
     let index=(this.page-1)*this.items_page;

     this.boletines_a_mostrar=[];
      for (index; index < this.items_page*this.page; index++) {
       if(this.editableSearchFilters[index]){
        this.boletines_a_mostrar.push(this.editableSearchFilters[index]);
       }   
        
      }
      this.current_page=event;
      console.log(this.current_page);
   }
/*
  private subscribe(): void {
    // console.log("in subscribe");
    this.subscription = this.userService.getSearchFiltersGetMessage()
    .subscribe((filters) => {      
      this.originalSearchFilters = filters;
      this.editableSearchFilters = filters;
      //console.log('FiltersComponent->ngOnInit->subscribe', this.editableSearchFilters);
    });
  }*/
}

