import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';
import { Router,ActivatedRoute } from '@angular/router';
import { UserService } from '../_services/user.service';
import { MzToastService } from 'ngx-materialize';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private route:ActivatedRoute,
    private toastService: MzToastService,  
  ) { }

  ngOnInit() {
    this.loginFormGroup = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    // si hay una ruta en la url la retorna, sino carga la predefinida
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/news';

    document.body.style.overflowY = "visible";
  }


  /* onKeydown(event) {
    if (event.key === "Enter") {
      this.login();
    }
  } */
  onSubmit(event) {
   // console.log(event);

    if (this.loginFormGroup.invalid) {
      this.toastService.show('Nombre de usuario o contraseña incorrectos.', 5000, 'red');
      console.log('form invalid');
      return;
    }
    this.login();
  }

  login() {
    //console.log('LoginComponent->login().. login clicked!');
    let username = this.loginFormGroup.controls.username.value;
    let password = this.loginFormGroup.controls.password.value;
    //console.log('LoginComponent->login() ', 'u: ' + username + ' p:' + password);

    this.authenticationService.authenticate(username, password)
      .subscribe((authenticated) => {
        if (authenticated) {
          this.userService.setUser(JSON.parse(localStorage.getItem('user')));
          this.userService.getSearchFilters().subscribe((searchFilters)=>{
            this.userService.getSearchFiltersSendMessage(searchFilters);
          });
          
            this.router.navigateByUrl(this.returnUrl);
          //this.router.navigate(['/news']);
        }else{
          this.toastService.show('Nombre de usuario o contraseña incorrectos.', 5000, 'red');

        }
      });
  }


}
