import { Component, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { UserService } from 'src/app/_services/user.service';
import { NewsService } from 'src/app/_services/news.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MzModalComponent, MzToastService, MzDatepickerModule, MzValidationModule,MzSidenavComponent } from 'ngx-materialize';
import { SearchFilter } from 'src/app/_models/search_filter';
import { Subscription,Observable } from 'rxjs';
import { NewsComponent } from '../../news/news.component';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  nombre_pdf: string = '';
  editIndex: number;
  search: string = '';
  searchTag: string = '';
  medio_prensa = true;
  medio_online = true;
  medio_tv = true;
  medio_radio = true;
  medio_temp="";
  start_date="";
  end_date="";
  restrictiva=false;
  medios_seleccionados:string[]=[];
  medios_seleccionados_sidebar:string[]=[];
  isSidebarOpen: boolean = false;
  currentSearchFilterEdit: SearchFilter;
  edit: boolean;
  selectedEditRegions:string[]=[];
  selectedEditRegions_sidebar:string[]=[];
  editableSearchFilters: any;
  originalSearchFilters: any;
  tagValue:string = "";
  regions:string[]=['ES','AR','CO','UY','CL','MX']; /*,'PE'*/
  regionsFull:string[]=['España','Argentina','Colombia','Uruguay','Chile','Mexico']; /*,'PE'*/
  medios:string[]=['Prensa','Online','Radio','Televisión'];
  private subscription: Subscription;
  userSelectedNews: String[];

  constructor(
    private userService: UserService,
    private newsService: NewsService,
    private authenticationService: AuthenticationService,
    public router: Router,
    private _location: Location,
    private toastService: MzToastService
  ) {
    
    this.newsService.searchChanged.subscribe(()=>{
      this.search = '';
      });
  }
  
  selectRegionES=localStorage.getItem('regionES');
  selectRegionAR=localStorage.getItem('regionAR');
  selectRegionCL=localStorage.getItem('regionCL');
  selectRegionCO=localStorage.getItem('regionCO');
  selectRegionUY=localStorage.getItem('regionUY');
  selectRegionPE=localStorage.getItem('regionPE');
  selectRegionMX=localStorage.getItem('regionMX');

  user=this.userService.user;

  @ViewChild('createSheetModal') createSheetModal: MzModalComponent;

  @ViewChild('sidenav-demo2') sidebar:MzSidenavComponent;

  @ViewChild(NewsComponent) news: NewsComponent;

  public options: Pickadate.DateOptions = {
    monthsFull: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		weekdaysFull: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    weekdaysLetter: ["D","L", "M", "M", "J", "V", "S"],
    labelMonthNext: 'Siguiente mes',
		labelMonthPrev: 'Mes anterior',
		labelMonthSelect: 'Selecciona un mes',
		labelYearSelect: 'Selecciona un año',
    clear: 'Cancelar ', // Clear button text
    close: 'Aceptar',    // Ok button text
    today: 'Hoy', // Today button text
    firstDay: 1,
    format: 'dd/mm/yyyy',
    formatSubmit: 'dd-mm-yyyy',    
    closeOnSelect:true,
  }; 
  public options_semanal: Pickadate.DateOptions = {
    monthsFull: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		weekdaysFull: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    weekdaysLetter: ["D","L", "M", "M", "J", "V", "S"],
    labelMonthNext: 'Siguiente mes',
		labelMonthPrev: 'Mes anterior',
		labelMonthSelect: 'Selecciona un mes',
		labelYearSelect: 'Selecciona un año',
    clear: 'Cancelar', // Clear button text
    close: 'Aceptar',    // Ok button text
    today: '', // Today button text
    firstDay: 1,
    format: 'dddd',
    formatSubmit: 'dd-mm-yyyy',   
    closeOnSelect:true,
  }; 
  public options_mensual: Pickadate.DateOptions = {
    monthsFull: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		weekdaysFull: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    weekdaysLetter: ["D","L", "M", "M", "J", "V", "S"],
    labelMonthNext: 'Siguiente mes',
		labelMonthPrev: 'Mes anterior',
		labelMonthSelect: 'Selecciona un mes',
		labelYearSelect: 'Selecciona un año',
    clear: 'Cancelar', // Clear button text
    close: 'Aceptar',    // Ok button text
    today: '', // Today button text
    firstDay: 1,
    format: 'dd',
    formatSubmit: 'dd-mm-yyyy',  
    closeOnSelect:true,  
  }; 

  public modalOptions: Materialize.ModalOptions = {
    dismissible: false, // Modal can be dismissed by clicking outside of the modal
    opacity: .5, // Opacity of modal background
    inDuration: 300, // Transition in duration
    outDuration: 200, // Transition out duration
    startingTop: '100%', // Starting top style attribute
    endingTop: '5%', // Ending top style attribute
    ready: (modal, trigger) => { // Callback for Modal open. Modal and trigger parameters available.
    },
    
    complete: () => { }, // Callback for Modal close    this.resetFormValues();
  
  };

  ngOnInit() {
    
  }
  closeSidebar(){
    this.sidebar.closeOnClick;
  }
  opensidenav2(){
    this.addMedio();
    //console.log('entra open sinde bar 2');
    let userKeywords=this.newsService.keywords_temp.toString();
    
    if(userKeywords!=undefined){
      const newkw = [];
      let keywords=userKeywords.split(',');  
      keywords.forEach(el => {
        if (!(el in newkw)) {
          newkw[el] = true;
          newkw.push(el);
        }
      });
      console.log('entra open sinde bar 2::'+newkw);
      this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],this.start_date,this.end_date,null,'','',this.selectedEditRegions.toString(),this.medios_seleccionados.toString(),this.restrictiva);
      newkw.forEach(element => {
        if(element!=''){
          console.log('entra open sinde bar 2');
          this.currentSearchFilterEdit.keywords.push(element.trim());
        }
        
      });
    }   
  }
  opensidenav3(){
    this.userSelectedNews=this.userService.getSelectedNews();

    console.log(this.newsService.newsCollection);
   // alert(this.news.sendSelectedNews());
  }
  muestraLoading(){
    $('#loading-container2').show();
  }
  createPDF(){
    //console.log("genera PDF de las noticias:"+this.userSelectedNews);
 
    this.userService.resetSelectedNews();
    window.location.reload();
  //  this.router.navigateByUrl('/');
  }
 
  getAllFilters(){
    
    this.subscription =this.userService.getSearchFilters().subscribe((searchFilters) => {
      this.originalSearchFilters = searchFilters;
      this.editableSearchFilters = this.originalSearchFilters;
    })
  }
  openModelFilter(){
 /*   this.resetFormValues();
    this.addMedio();
    this.addRegion();
    this.medios_seleccionados=null;
    this.addMedio();

    this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],this.start_date,this.end_date,null,'','',this.selectedEditRegions.toString(),this.medios_seleccionados.toString());
*/
    this.createSheetModal.openModal();




  }
  editSearchFilter(searchFilter) {

    this.addMedio();
    this.addRegion();
    this.edit = true;
    if(!this.start_date){
      let now=new Date();
      this.start_date=now.toTimeString();
    }
    if (this.currentSearchFilterEdit){
      console.log('entra existeix');
      this.cancelEditSearchFilter();
      this.addMedio();
      this.addRegion();

    searchFilter.medios=this.medios_seleccionados.toString();
    searchFilter.region=this.selectedEditRegions.toString();
    this.currentSearchFilterEdit = searchFilter;
    //console.log("nou?2->"+JSON.stringify(this.currentSearchFilterEdit));
    }
      
    // this.currentSearchFilterEdit = searchFilter;

    if(!this.currentSearchFilterEdit || this.currentSearchFilterEdit==null){
    //  console.log('entra no existeix');
      this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],this.start_date,this.end_date,null,'','',this.selectedEditRegions.toString(),this.medios_seleccionados.toString(),this.restrictiva);
    }
    

    if(this.currentSearchFilterEdit.region){
      this.selectedEditRegions=this.currentSearchFilterEdit.region.split(",");
    }
    
    if(this.currentSearchFilterEdit.medios){
      this.medios_seleccionados=this.currentSearchFilterEdit.medios.split(",");
    }
    
    //console.log("ediit->"+JSON.stringify(this.currentSearchFilterEdit));

    //this.editableSearchFilters = this.originalSearchFilters.filter(filter => filter !== searchFilter);
    //console.log('currentSearchFilterEdit: ', this.currentSearchFilterEdit);
    this.openModelFilter();
  }

  cancelEditSearchFilter() {
    this.edit = false;
    this.currentSearchFilterEdit = null;
    this.selectedEditRegions=[];
    this.medios_seleccionados=[];
    

    this.editableSearchFilters = this.originalSearchFilters;
    this.getAllFilters();

  }
 
  resetFormValues(){
    this.cancelEditSearchFilter();
    }
  onKeydown(event) {
  
    if (event.key === "Enter") {
      
      var res = this.router.url.split("/");
    //  console.log(res[2]);
      if(this.router.url=='/news/filtros'){
        this.restrictiva=false;
        this.router.navigateByUrl('/news');
        this.newsService.searchByTextInFilter(this.userService.tipoNoticia, this.search);
      }if(res[2]=="detail"){
        this.restrictiva=false;
        this.userService.detail=true;
        this.userService.search=this.search;
        
        this.newsService.searchByTextInFilter(this.userService.tipoNoticia, this.search);
        if (event.key == "Enter") {//solo si se ha pulsado enter tirara la vista atras ( se hace porque si no se busca nada no tire atras )
          this.router.navigateByUrl('/news');;
        }
     
      
      }else{
        this.restrictiva=false;
        this.userService.search=this.search;
        this.newsService.searchByText(this.userService.tipoNoticia, this.search);
      }
      this.addKeywordSearch(event.target.value);
      this.newsService.keywords_temp=[];
      this.newsService.keywords_temp.push(event.target.value);
      console.log(this.newsService.keywords_temp);
      $('#loading-container2').show();
    }
    else
      this.search = event.target.value;
      this.newsService.search_temp=(event.target.value);
  }
  onKeydownFilterName(event) {
    
  }
  onKeydownFilter(event) {
    
    if (event.key === "Enter") {
      if(event.target.value!=''){
        this.addKeyword(event.target.value);
        
        event.target.value = null;
       }
    }
    if (event.key === ",") {
      if(event.target.value!=''){
        this.addKeyword(event.target.value);
        event.target.value = null;
        return false;
      }else{
        return false;
      }

    }
  }

  saveFilter(){
    this.openModelFilter();
  }

  applyFilter(){
    this.newsService.primer_total=false;
    this.newsService.primer_total_envio=false;

    this.addMedio();
    this.addRegion();     

    this.newsService.search_temp="";
    if(this.searchTag!=""){
      this.addKeyword(this.searchTag);
      this.newsService.search_temp=this.searchTag;
    }else{
      this.newsService.search_temp="";
    }
   
    if (!this.currentSearchFilterEdit){
      if(this.searchTag!=""){
        this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],this.start_date,this.end_date,null,'','','','',this.restrictiva);
        this.currentSearchFilterEdit.keywords.push(this.searchTag.trim());
        this.currentSearchFilterEdit.restrictiva=this.restrictiva;
        this.newsService.keywords_temp.push(this.searchTag.trim());
      }else{
        this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],this.start_date,this.end_date,null,'','','','',this.restrictiva);
        this.newsService.keywords_temp=[];
      }
  
    }else{
      this.currentSearchFilterEdit.medios=this.medios_seleccionados.toString();
      this.currentSearchFilterEdit.start_date=this.start_date;
      this.currentSearchFilterEdit.end_date=this.end_date;
      this.currentSearchFilterEdit.restrictiva=this.restrictiva;
    }
    if(this.medios_seleccionados.length==1){
      switch (this.medios_seleccionados.toString()) {
        case 'Online':
          this.router.navigate(['news/online']); 
          break;
        case 'Prensa':
            this.router.navigate(['news/prensa']); 
            break;
        case 'Radio':
            this.router.navigate(['news/radio']); 
            break;
        case 'Televisión':
            this.router.navigate(['news/tv']); 
            break;
      }
     // this.userService.selectSearchFilter(JSON.parse(JSON.stringify(this.currentSearchFilterEdit)));
     // this.newsService.searchByFilterSidebar(0, this.currentSearchFilterEdit,this.medios_seleccionados.toString(),null);
    }else{
     // this.router.navigate(['news']); 
     this.userService.selectSearchFilter(JSON.parse(JSON.stringify(this.currentSearchFilterEdit)));
      this.router.navigate(['news']); 
      this.newsService.applyFilter=0;
    }
   //this.newsService.searchByTextSidebar(this.userService.tipoNoticia,search ,this.start_date,this.end_date,this.medios_seleccionados);
   this.userService.selectSearchFilter(JSON.parse(JSON.stringify(this.currentSearchFilterEdit)));
   this.newsService.searchByFilterSidebar(0, this.currentSearchFilterEdit,this.medios_seleccionados.toString(),null);
   this.muestraLoading();
    
    /*     closeSidebar(); */

   // this.router.navigate(['news']); 


  }
  updateMedio(){
    console.log("enter");
  }
  addMedio(){
    this.medios_seleccionados=null;
    this.medios_seleccionados=[];
   
    if(this.medio_prensa){
      this.medios_seleccionados.push("Prensa");
    }
    if(this.medio_online){
      this.medios_seleccionados.push("Online");
    }
    if(this.medio_radio){
      this.medios_seleccionados.push("Radio");
    }
    if(this.medio_tv){
      this.medios_seleccionados.push("Televisión");
    }
    this.userService.setMedios(this.medios_seleccionados);
   //console.log("entra_> "+this.medios_seleccionados);
  }


  addRegion(){
    this.selectedEditRegions=[];
    if( localStorage.getItem('regionES')!=''){
      this.selectedEditRegions.push("ES");
    }
    if(localStorage.getItem('regionAR')!=''){
      this.selectedEditRegions.push("AR");
    }
    if(localStorage.getItem('regionCL')!=''){
      this.selectedEditRegions.push("CL");
    }
    if(localStorage.getItem('regionCO')!=''){
      this.selectedEditRegions.push("CO");
    }
    if(localStorage.getItem('regionPE')!=''){
      this.selectedEditRegions.push("PE");
    }
    if(localStorage.getItem('regionUY')!=''){
      this.selectedEditRegions.push("UY");
    }
    if(localStorage.getItem('regionMX')!=''){
      this.selectedEditRegions.push("MX");
    }
  }

  selectMedio(medio){
   if(this.router.url.replace('/news/','')!=medio){
    if(this.medio_temp!=medio){
      this.medio_temp=medio;
      this.muestraLoading();
    }
   }
    
    
    switch(medio) { 
      case 'all': { 
  //    this.newsService.primer_total=false;
        this.medio_prensa = true;
        this.medio_online = true;
        this.medio_tv = true;
        this.medio_radio = true;
        
        break; 
      } 
      case 'radio': { 
        this.medio_prensa = false;
        this.medio_online = false;
        this.medio_tv = false;
        this.medio_radio = true;
        break; 
      } 
      case 'tv': { 
        this.medio_prensa = false;
        this.medio_online = false;
        this.medio_tv = true;
        this.medio_radio = false;
        break; 
      }
      case 'online': { 
        this.medio_prensa = false;
        this.medio_online = true;
        this.medio_tv = false;
        this.medio_radio = false;
        break; 
      } 
      case 'prensa': { 
        this.medio_prensa = true;
        this.medio_online = false;
        this.medio_tv = false;
        this.medio_radio = false;
        break; 
      } 
   } 
   let userKeywords=this.newsService.keywords_temp.toString();
   if(userKeywords!=''){
   // console.log("no hay keywords::>"+userKeywords);
    this.end_date=this.currentSearchFilterEdit.end_date;
    this.start_date=this.currentSearchFilterEdit.start_date;
  //  this.newsService.primer_total=true;
    //this.currentSearchFilterEdit = null;
    
   }else{
    this.newsService.primer_total=false;
    this.newsService.primer_total_envio=false;
    this.end_date="";
    this.start_date="";
    this.currentSearchFilterEdit = null;
   }
       
  
     
   
  // this.end_date="";
   this.searchTag="";
   //this.start_date="";
   this.medios_seleccionados=[];
   this.addMedio();
   console.log("cambio de medio con fechas:->"+this.start_date+"  "+this.end_date);
   this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],this.start_date,this.end_date,null,'','',this.selectedEditRegions.toString(),this.medios_seleccionados.toString(),this.restrictiva);

   this.userService.selectSearchFilter(JSON.parse(JSON.stringify(this.currentSearchFilterEdit)));
  }
  activeRegionES(){
    this.muestraLoading();
    if(this.selectRegionES==''){
      this.selectRegionES='ES';
      localStorage.setItem('regionES','ES');
    }else{
      this.selectRegionES='';
      localStorage.setItem('regionES','');
    }
    let urlName = this.router.url.replace('/', '');
    if(urlName!="news/filtros"){
      if(this.userService.selectedSearchFilter){
        this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
      }else{
        this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
      }
    }

   
    
  }
  activeRegionAR(){
    this.muestraLoading();
    if(this.selectRegionAR==''){
      this.selectRegionAR='AR';
      localStorage.setItem('regionAR','AR');
    }else{
      this.selectRegionAR='';
      localStorage.setItem('regionAR','');
    }
    let urlName = this.router.url.replace('/', '');
    if(urlName!="news/filtros"){
      if(this.userService.selectedSearchFilter){
        this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
      }else{
        this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
      }
    }
  }
  activeRegionCL(){
    this.muestraLoading();
    if(this.selectRegionCL==''){
      this.selectRegionCL='CL';
      localStorage.setItem('regionCL','CL');
    }else{
      this.selectRegionCL='';
      localStorage.setItem('regionCL','');
    }    
    let urlName = this.router.url.replace('/', '');
    if(urlName!="news/filtros"){
      if(this.userService.selectedSearchFilter){
        this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
      }else{
        this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
      }
    }
  }
  activeRegionCO(){
    this.muestraLoading();
    if(this.selectRegionCO==''){
      this.selectRegionCO='CO';
      localStorage.setItem('regionCO','CO');
    }else{
      this.selectRegionCO='';
      localStorage.setItem('regionCO','');
    }
    let urlName = this.router.url.replace('/', '');
    if(urlName!="news/filtros"){
      if(this.userService.selectedSearchFilter){
        this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
      }else{
        this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
      }
    }
  }
  activeRegionUY(){
    this.muestraLoading();
    if(this.selectRegionUY==''){
      this.selectRegionUY='UY';
      localStorage.setItem('regionUY','UY');
    }else{
      this.selectRegionUY='';
      localStorage.setItem('regionUY','');
    }
    let urlName = this.router.url.replace('/', '');
    if(urlName!="news/filtros"){
      if(this.userService.selectedSearchFilter){
        this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
      }else{
        this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
      }
    }
  }
  activeRegionMX(){
    this.muestraLoading();
    if(this.selectRegionMX==''){
      this.selectRegionMX='MX';
      localStorage.setItem('regionMX','MX');
    }else{
      this.selectRegionMX='';
      localStorage.setItem('regionMX','');
    }
    let urlName = this.router.url.replace('/', '');
    if(urlName!="news/filtros"){
      if(this.userService.selectedSearchFilter){
        this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
      }else{
        this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
      }
    }
  }
  activeRegionPE(){
    this.muestraLoading();
    if(this.selectRegionPE==''){
      this.selectRegionPE='PE';
      localStorage.setItem('regionPE','PE');
    }else{
      this.selectRegionPE='';
      localStorage.setItem('regionPE','');
    }
    let urlName = this.router.url.replace('/', '');
    if(urlName!="news/filtros"){
      if(this.userService.selectedSearchFilter){
        this.newsService.searchByFilter(this.userService.newsType,this.userService.selectedSearchFilter);
      }else{
        this.newsService.searchByText(this.userService.tipoNoticia, this.userService.search);
      }
    }
  }
  
  logout() {
    this.authenticationService.logout()
      .subscribe((loggedOut) => {
        if (loggedOut) {
          this.router.navigateByUrl('/login');
        }
      });
  }

  /** Keywords  */
  addKeywordSearch(keyword: string) {
    this.resetFormValues();
    this.addRegion();
    this.addMedio();
    if(keyword!=""){
       if (!this.currentSearchFilterEdit)
    this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],'','',null,'','',this.selectedEditRegions.toString(),this.medios_seleccionados.toString(),this.restrictiva);
    this.currentSearchFilterEdit.keywords.push(keyword.trim());
    
    }
    this.userService.selectSearchFilter(JSON.parse(JSON.stringify(this.currentSearchFilterEdit)));
    this.searchTag="";
  }


  addKeyword(keyword: string) {
    console.log('entra');
    this.addRegion();
    this.addMedio();
    if(keyword!=""){
       if (!this.currentSearchFilterEdit)
    this.currentSearchFilterEdit = new SearchFilter(0, this.userService.user.id, '', '', [],'','',null,'','',this.selectedEditRegions.toString(),this.medios_seleccionados.toString(),this.restrictiva);
    this.currentSearchFilterEdit.keywords.push(keyword.trim());
    this.newsService.keywords_temp.push(keyword.trim());
    
    }
    this.searchTag="";
  }
  removeKeyword(index) {
    //alert("removeee");

    if (this.currentSearchFilterEdit.keywords[index]) {
     // console.log('remove');
      this.newsService.search_temp.replace(this.currentSearchFilterEdit.keywords[index],'');
      this.currentSearchFilterEdit.keywords.splice(index, 1);
      this.newsService.keywords_temp.splice(index, 1);
    }
    if(this.currentSearchFilterEdit.keywords.length==0){
      this.currentSearchFilterEdit = null;
      this.newsService.search_temp='';
      this.newsService.keywords_temp=[];
    //  console.log("entra remove 0");
    }
  }
  createSearchFilter() {
    this.currentSearchFilterEdit.name = this.currentSearchFilterEdit.name.trim();
    //console.log('new filter -> ', this.currentSearchFilterEdit);
    this.userService.saveSearchFilter(this.currentSearchFilterEdit)
      .subscribe((created) => {
        if (!created){
          this.toastService.show('No se ha podido crear el filtro', 5000);
          throw new Error('No se ha podido crear el filtro');
        }
        
        this.toastService.show('Filtro guardado correctamente', 5000, 'green');
        this.resetFormValues();
        this.start_date=null;
        this.currentSearchFilterEdit = null;
        this.selectedEditRegions=[];
        this.medios_seleccionados=[];
        
        this.userService.getSearchFilters().subscribe((searchFilters) => {
          this.originalSearchFilters = searchFilters;
          this.editableSearchFilters = this.originalSearchFilters;
        })
        //this.originalSearchFilters.unshift(this.currentSearchFilterEdit);
      });
  }
  generatePdf() {
   
    let seleccionados=[];
    $('#loading-container').show();
    $('#loading-container2').show();
    $('.container_boletin input[type="checkbox"]:checked').each(function() {
      seleccionados.push($(this).val());
    });
    this.userService.selectedNewsFinalSend=seleccionados;
    this.userService.pdf_name=$('#pdf-name').val().toString().replace(/ /g,'_');
    this.userService.pdf_description=$('#pdf-description').val().toString();
   // console.log($('#pdf-name').val().toString()+'---'+$('#pdf-description').val().toString());
    this.userService.generatePdf()
      .subscribe((created) => {
        if (!created){
          $('#loading-container').hide();
          $('#loading-container2').hide();
          this.toastService.show('No se ha podido crear el PDF', 5000);
          throw new Error('No se ha podido crear el PDF');
        }
        $('#loading-container').hide();
        $('#loading-container2').hide();
        this.toastService.show('PDF creado correctamente', 5000, 'green');
        $('#btn-sidenav-demo3').click();
        this.userService.resetSelectedNews();
        $('#pdf-description').val('');
        $('#pdf-name').val('');
        this.nombre_pdf='';
      });
  }
  validatorForm(type){
    this.addMedio();
    if(this.currentSearchFilterEdit.notification_type=="sin"){
      this.currentSearchFilterEdit.notification=0;
    }else{
      this.currentSearchFilterEdit.notification=1;
    }
    this.currentSearchFilterEdit.medios=this.medios_seleccionados.toString();
    this.currentSearchFilterEdit.start_date=this.start_date;

  //  this.addRegion();
    if(this.currentSearchFilterEdit.medios=="" || this.currentSearchFilterEdit.name=="" || this.currentSearchFilterEdit.keywords.length==0 ||this.selectedEditRegions.length==0 ||(this.currentSearchFilterEdit.notification==1 && this.currentSearchFilterEdit.notification_type!="diarias" && this.currentSearchFilterEdit.notification_day=="") ){
      if(this.currentSearchFilterEdit.name==""){
        this.toastService.show('El Nombre del filtro es obligatorio.', 5000, 'red');
      }
      if(this.currentSearchFilterEdit.keywords.length==0){
        if(this.tagValue.length>0){
          this.addKeyword(this.tagValue);
          this.tagValue='';
        }else{
          this.toastService.show('Los Tags son obligatorios.', 5000, 'red');
        }
      }
      if(this.currentSearchFilterEdit.medios==""){
        this.toastService.show('Mínimo un medio seleccionado', 5000, 'red');
      }
      if(this.selectedEditRegions.length==0){
        this.toastService.show('Mínimo una región seleccionada', 5000, 'red');
      }
      if(this.currentSearchFilterEdit.notification_day==""&& this.currentSearchFilterEdit.notification==1){
        this.toastService.show('El Dia de Notificación es obligatorio.', 5000, 'red');
      }

    }else{

        this.currentSearchFilterEdit.region=this.selectedEditRegions.toString();
        this.currentSearchFilterEdit.medios=this.medios_seleccionados.toString();
        this.createSearchFilter();
      
      
      this.createSheetModal.closeModal();
    }
  }
  

}
